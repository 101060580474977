import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import { TestimonialGrid, TestimonialCard } from '../../components/TestimonialGrid'
import Default from '../../components/Layouts/Default';

import IntelligentPhoneNumbers from '../images/intelligentPhoneNumbers.png'
import SfHalf from '../images/sfHalf.png'
import TeamDashHalf from '../images/teamDashHalf.svg'
import LibertyRing from '../animations/libertyRing.json'
import ActionEvent from '../images/actionEvent.svg';
import BestSalesforce from '../images/bestSalesforce.svg';
import SalesforceGraph from '../images/salesforceGraph.png';


var lottie

export default class BetterCallCenter extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: LibertyRing,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Conversation Intelligence in Salesforce</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='telephony1'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Call Transcription
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
              The First Ever Salesforce Native Conversation Intelligence Platform
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="blueToGreen">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle2 color={colors.white} mb="2rem" mt="2rem">
                Trusted By Category Defining Sales<br />& Customer Success Teams
              </PageTitle2>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="jackie" name="Jackie F" company="NewFront Insurance" quote="I love how you are able to listen to other colleagues calls while training. That live feature helped me significantly when I was training. I also like that you can see who is actively on a call."/>
              <TestimonialCard avatar="taylor" name="Taylor S" company="Cloud Kitchens" quote="Truly is great because it easily integrates with Salesforce to track my activity - both calls and texts. I also love the voicemail drop feature, as well as the mobile app that syncs flawlessly with my iPhone."/>
              <TestimonialCard avatar="billy" name="Billy B" company="Galvaninze" quote="I love how I can switch my number based on the purpose of my call and how easy Truly is to use. It's awesome I can listen to any of my colleagues phone calls at any time. It was a tremendous learning tool for me in my new job. The voicemail drop function is a game changer."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} py={['6rem', '0rem']} background={colors.trulyDark}>
            <Box width={[1/2, 1/3]}>
              <CenteredImg src={BestSalesforce} alt="Intelligent Phone Numbers"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
              LIBERATE THE DATA IN YOUR CALL RECORDINGS
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Less than 1% of conversational data ever makes it to CRM.  For most organizations, all of this valuable data remains locked and forgotten in their call recordings.
                <br/><br/>
                Truly automatically transcribes, analyzes and indexes your calls in your CRM to make your calls fully searchable and reportable out of the box.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid color='#292929'>
          <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              AUTOMATE DATA ENTRY AND BUILD DELIGHTFUL CUSTOMER EXPERIENCES
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
              Most intelligence providers operate as walled gardens with your transcription data, limiting your ability to innovate using your full revops stack.
              <br/><br/>
              Truly offers the only platform that enables you to own, access and analyze your transcription data in Salesforce or any other 3rd party analytics platform.  It's also the only solution that lets you build customized, no-code analytics in Salesforce.
              </Large>
            </Box>
            <Box width={[1/2, 1/2]} p="32pt">
              <CenteredImg src={ActionEvent} alt="Salesforce"/>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '0rem']}>
            <Box width={[1/2, 1/2]} pb="2rem">
              <CenteredImg src={SalesforceGraph} alt="Team Dashboard"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              QUANTIFY AND MEASURE THE BEHAVIORS THAT CLOSE MORE DEALS 
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              All great sales people exhibit similar behaviors - active listening, asking complex questions and avoiding interruptions. 
              <br/><br/>
              With Truly, you can measure these behaviors using out of the box reports or define custom metrics specific to your sales motion and business, to define, measure and spread these best practices to your entire sales organization.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
